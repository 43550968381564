export default {
  data: function () {
    return {
      filterDropdown: {
        "exp_pre_TipoActuacion.TipoActuacionID": {
          optionValue: "value",
          optionLabel: "name",
          options: [],
        },
        "exp_pre_EstadoActuacion.TipoEstadoActuacionID": {
          optionValue: "value",
          optionLabel: "name",
          options: [
            { name: "En Curso", value: "En Curso" },
            { name: "Vencida", value: "Vencida" },
            { name: "Finalizada", value: "Finalizada" },
          ],

        },
        DependenciaID: {
          optionValue: "id",
          optionLabel: "dependencia",
          options: [],

          changeDropdown: async (value, filters) => {
            self = this;
            let dependenciaID = filters ? value.id : value//si es llamada desde el create value ya es el id, caso contrario selecciona el id dentro de value
            if (this.$rest.esTipoUsuario(['Super User'], this.$store.state.user)){//Si es un Super User no queremos que filtre por la dependencia, queremos que traiga todos los User
              dependenciaID = null;
            }
            self.filterDropdown["exp_pre_ActuacionUsuarioDependencia.UsuarioID"].options = self.getActuacionesUserByStationId(dependenciaID)
          },
          refreshDropdown: async () => {
            await this.$store.dispatch("siis/setDependencias", true)
            this.filterDropdown["DependenciaID"].options = this.$store.state.siis.dependencia;
          },
          loadDropdown: async (self) => {
            if (!self) {
              self = this;
            }
            await self.$store.dispatch("siis/setDependencias");
            self.filterDropdown["DependenciaID"].options = self.$store.state.siis.dependencia;
          }
        },
        propietario: {
          optionValue: "value",
          optionLabel: "name",
          options: [
            { value: 0, name: "Mis registros" },
            { value: 1, name: "Dependencia Registros" },
          ],
        },
        RolesOficiales: {
          optionValue: "value",
          optionLabel: "name",
          options: [
            { value: "jefe de sumario", name: "Jefe de sumario" },
            { value: "jefe de dependencia", name: "Jefe de dependencia" },
            { value: "oficial sumariante", name: "Oficial sumariante" },
          ],
        },
        ModusOperandi: {
          optionValue: "Nombre",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: async () => {
            await this.$store.dispatch("siis/setModusoperandi", true)
            this.filterDropdown["ModusOperandi"].options = this.$store.state.siis.modusoperandi;
          },
          loadDropdown: async (self) => {
            if (!self) {
              self = this;
            }
            await self.$store.dispatch("siis/setModusoperandi");
            self.filterDropdown["ModusOperandi"].options = self.$store.state.siis.modusoperandi;
          }
        },
        Articulo: {
          optionValue: "ArticulosID",
          optionLabel: "Numeroarticulo",
          options: [],
          refreshDropdown: async () => {
            await this.$store.dispatch("siis/setArticulos", true)
            this.filterDropdown["Articulo"].options = this.$store.state.siis.articulos;
          },
          loadDropdown: async (self) => {
            if (!self) {
              self = this;
            }
            await self.$store.dispatch("siis/setArticulos");
            self.filterDropdown["Articulo"].options = self.$store.state.siis.articulos;
          }
        },    
        Departamental: {
          optionValue: "DepartamentalID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: async () => {
            await this.$store.dispatch("siis/setDepartamentales", true)
            this.filterDropdown["Departamental"].options = this.$store.state.siis.Departamentales;
          },
          loadDropdown: async (self) => {
            if (!self) {
              self = this;
            }
            await self.$store.dispatch("siis/setDepartamentales");
            self.filterDropdown["Departamental"].options = self.$store.state.siis.departamentales;
            console.log("self.filterDropdown[Departamental].options: ", self.filterDropdown["Departamental"].options);
          }
        },        
        Sitio: {
          optionValue: "TipoSitioID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: async () => {
            await this.$store.dispatch("siis/setSitio", true)
            this.filterDropdown["Sitio"].options = this.$store.state.siis.sitio;
          },
          loadDropdown: async (self) => {
            if (!self) {
              self = this;
            }
            await self.$store.dispatch("siis/setSitio");
            self.filterDropdown["Sitio"].options = self.$store.state.siis.sitio;
          }
        },            
        "exp_pre_ActuacionUsuarioDependencia.UsuarioID": {
          optionValue: "UsuarioID",
          optionLabel: "apellido_nombre",
          options: [],
        },

        actuacionesOf: { id: 1, option: "Dependencia Registros" }
      },

      filtersFields: [
        {
          name: "Actuaciones",
          field: "exp_pre_TipoActuacion.TipoActuacionID",
          hideOperators: true
        },
        { name: "Numero de Sumario", field: "exp_pre_Actuacion.Numero" },//aca se solicito sacarle el acento a Sumarío en la Uh ss-3425
        {
          name: "Fecha de Creacion", field: "createdAt", type: 'date'
        },
        {
          name: "Fecha del Hecho", field: "FechaDelHecho", type: 'date'
        },
        {
          name: "DNI Denunciante",
          field: "exp_pre_PersonaFisica.Dni",
          addFilter: {
            "exp_pre_ActuacionPersonaFisica.TipoRolPersonaFisicaID": [
              { operator: "CONTAINS", value: "Denunciante" }//se usa CONTAINS porque el esto incluye al tipo de rol "Denunciante y Damnificado"
            ]
          }
        },
        {
          name: "DNI Damnificado",
          field: "exp_pre_PersonaFisica.Dni",
          addFilter: {
            "exp_pre_ActuacionPersonaFisica.TipoRolPersonaFisicaID": [
              { operator: "CONTAINS", value: "Damnificado" }//se usa CONTAINS porque el esto incluye al tipo de rol "Denunciante y Damnificado"
            ]
          }
        },
        {
          name: "Afectado Telefono",
          field: "exp_pre_PersonaFisica.Telefono",
          addFilter: {
            "exp_pre_ActuacionPersonaFisica.Telefono":[]
          }
        },        
        {
          name: "DNI Acusado",
          field: "exp_pre_PersonaFisica.Dni",
          addFilter: {
            "exp_pre_ActuacionPersonaFisica.TipoRolPersonaFisicaID": [
              { operator: "=", value: "Acusado" }
            ]
          }
        },
        {
          name: "Apodo",
          field: "exp_pre_PersonaFisica.Apodo",
          addFilter: {
            "exp_pre_ActuacionPersonaFisica.TipoRolPersonaFisicaID": [
              { operator: "=", value: "Acusado" }
            ]
          }
        },
        {
          name: "Estado",
          field: "exp_pre_EstadoActuacion.TipoEstadoActuacionID",
          addFilter: {
            "ConUsuarioAsignado": [
              { operator: "=", value: "true" }
            ]
          },
          hideOperators: true
        },
        {
          name: "Propietario",
          field: "propietario",
          hideOperators: true
        },
        {
          name: "Causa/Caratula",
          field: "exp_pre_Tipocausacaratula.TipocausacaratulaID",
        },
        {
          name: "Juzgado",
          field: "exp_pre_Roljuzgadosumario.Nombre",
        },
        {
          name: "Modus Operandi",
          field: "ModusOperandi",
          hideOperators: true
        },
        {
          name: "Articulo",
          field: "Articulo",
          hideOperators: true
        },
        {
          name: "Departamental",
          field: "Departamental",
          hideOperators: true
        },
        {
          name: "Oficiales por Rol",
          field: "RolesOficiales",
          hideOperators: true
        },
        {
          name: "Sitio",
          field: "Sitio",
          hideOperators: true
        },        
      ],
      enableFilterMultiple: true
    }
  },
  async created() {
    if (!this.$rest.esTipoUsuario(["Deposito Judicial", "Oficial Sumariante", "Jefe de Dependencia", "Jefe de Sumario"], this.user)) {
      //agrega filtro por dependencia
      this.filtersFields.push({
        name: "Dependencia",
        field: "DependenciaID",
        limit: 1,
        hideOperators: true,
      })
      this.filtersFields.push({
        name: "Dependencias",
        field: "DependenciaID",
        type: "multiple",
        multiple: true,
      })
      this.filterDropdown["DependenciaID"].loadDropdown(this)
    }
    //A continuacion quitamos los filtros de Tipo "UFI" para cuando se trata de un Usuario Judicial (ya que estos no tienen juzgado)
    if(this.$rest.esTipoUsuario(["Juez", "Ayudante de Juez"], this.user)){
      this.filterDropdown["exp_pre_TipoActuacion.TipoActuacionID"].options = this.filterDropdown["exp_pre_TipoActuacion.TipoActuacionID"].options.filter(option => !option.name.includes("UFI"));
      // Quitamos el filtro "Juzgado" ya qe el mismo no tiene sentido para Usuarios Judiciales
      this.filtersFields = this.filtersFields.filter(obj => obj.name !== "Juzgado");
      // Tambien quitamos el filtro "Propietario" ya qe el mismo no tiene sentido para Usuarios Judiciales
      // delete this.filterDropdown.propietario;
      this.filtersFields = this.filtersFields.filter(obj => obj.name !== "Propietario");
    }

    if (this.$rest.esTipoUsuario(['Super User', 'User Tester', 'Super Usuario de E3', 'Jefe de Sumario', 'Jefe de Dependencia', 'Dpto. Planeamiento'], this.$store.state.user)) {
      this.filtersFields.push({
        name: "Oficial", field: "exp_pre_ActuacionUsuarioDependencia.UsuarioID",
        hideOperators: true,
      })
      // await this.$store.dispatch("siis/setUserActuaciones")
      await this.$store.dispatch("siis/setUserList")
      //Como aun no se selecciona ninguna dependencia, se buscan los user, de la dependencia actual (del user logueado)  
      this.filterDropdown["DependenciaID"].changeDropdown(this.$store.state.user.userdata.dependenciaID, null)
    }
    this.filterDropdown["ModusOperandi"].loadDropdown(this)
    this.filterDropdown["Articulo"].loadDropdown(this)
    this.filterDropdown["Departamental"].loadDropdown(this)
    this.filterDropdown["Sitio"].loadDropdown(this)
    this.filtersFields.sort((a, b) => a.name.localeCompare(b.name));//Ordenamos los filtros
  },
  methods: {
      orderedDropdown() {
        return Object.keys(this.filterDropdown)
          .sort()
          .reduce((acc, key) => {
            console.log(key)
            acc[key] = this.filterDropdown[key];
            return acc;
          }, {});
    }
  }
}