<template>
    <div>    
        <Card class="flex" >
          <template #content>
            <div>
              <div class="flex text-2xl">
                <!-- <b><p class="inline mr-1">{{ actuationsTypeName }}</p> {{ !inputSwitchActuationStatus ? 'EN CURSO' : 'VENCIDOS' }}</b> -->
              </div>
              <div class="flex justify-content-center mt-5">
                <label for="canDelete" class="mr-3" :class="{ 'font-bold': !inputSwitchActuationStatus }">En curso</label>
                  <InputSwitch v-model="inputSwitchActuationStatus" />
                <label for="canDelete" class="ml-3" :class="{ 'font-bold': inputSwitchActuationStatus }">Vencidos</label>
              </div>
              <Chart type="pie" :data="chartData" :options="chartOptions" class="mt-3" v-if="!showProgressSpinner" />
              <ProgressSpinner
                style="width: 50px; height: 50px"
                strokeWidth="5"
                fill="#EEEEEE"
                animationDuration="1.8s"
                class="flex justify-content-center mt-5"
                v-if="showProgressSpinner"
              />
            </div>
          </template>
        </Card>
    </div>
</template>
<script>
import Chart from 'primevue/chart';
import statisticsMixin from "./mixins/statisticsMixin.js";

export default {
    name: "DashboardChart",
    mixins: [statisticsMixin],
    components: {
      Chart
    },
    props: {
    dependencia: {
      type: String,
      default: null,
    },
  },
    async created(){
        let basicUrl = process.env.VUE_APP_BACKEND_ENVIRONMENT;
        let search = [
      {
        "exp_pre_EstadoActuacion.TipoEstadoActuacionID": [
          { operator: "=", value: "En Curso" },
        ],
      },
      {
        "exp_pre_EstadoActuacion.TipoEstadoActuacionID": [
          { operator: "=", value: "Vencida" },
        ],
      },
    ];
        let paramsPendientes = `search=${JSON.stringify(search[0])}`;
    let paramsVencidos = `search=${JSON.stringify(search[1])}`;
        this.getData([
      `${basicUrl}/Actuacion/estadisticas?${paramsPendientes}`,
      `${basicUrl}/Actuacion/estadisticas?${paramsVencidos}`,
    ]);
    },
    watch: {
    dependencia(actual= "") {
      this.showProgressSpinner = true;
      this.setDependencia(actual);
    },
  },
  methods: {
    setDependencia(value) {
      let paramsPendientes = `search={"exp_pre_EstadoActuacion.TipoEstadoActuacionID":[{"operator":"=","value":"En Curso"}],"DependenciaID":[{"operator":"=","value":"${value}"}]}`;
      let paramsVencidos = `search={"exp_pre_EstadoActuacion.TipoEstadoActuacionID":[{"operator":"=","value":"Vencida"}],"DependenciaID":[{"operator":"=","value":"${value}"}]}`;
      if(!value){
        paramsPendientes = paramsPendientes.replace(`,"DependenciaID":[{"operator":"=","value":"${value}"}]}`,"}")
        paramsVencidos = paramsVencidos.replace(`,"DependenciaID":[{"operator":"=","value":"${value}"}]}`,"}")
      }
      let basicUrl = process.env.VUE_APP_BACKEND_ENVIRONMENT
      this.getData([
        `${basicUrl}/Actuacion/estadisticas?${paramsPendientes}`,
        `${basicUrl}/Actuacion/estadisticas?${paramsVencidos}`,
      ]);
    },
  }
}

</script>
<style>
.logo {
  display: flex;
  justify-content: center;

}
</style>

