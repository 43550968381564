import Vue from 'vue'
import db from '../db';
import commonRepository from './commonRepository';

const table = db.actuations;

const actuationsRepository = {
  ...commonRepository(table),
  async addActuation(item) {
    try {
      return await table.add(item);
    } catch (error) {
      Vue.prototype.$toast.open({ 
        message: "Hubo un problema en la creación de la actuación Offline", 
        position: 'top-right', 
        type: 'error', 
        duration: 10000, 
      });
      throw error;
    }
  },
};

export default actuationsRepository;