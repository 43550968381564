export default {
  data: () => ({
    totalRows: null,
    offset: 0,
    loading: false,
    page: 1,
    data: [],
    lastfilter: '',
    lasturl: null,
  }),
  methods: {
    getTotalRows(filter, cleanFilters = false) {
      return new Promise(async (resolve) => {
        try {
          // console.log("getTotalRows", 1)
          // if (this.lastfilter != JSON.stringify(filter)){
            this.lastfilter = JSON.stringify(filter)
            if(this.removeLimit)  this.lasturl = this.lasturl.replace(/limit=[^&]*&/, '');
            let getOptions = {
              url: this.removeOffset(cleanFilters ? this.url : this.lasturl),
              params: { getTotal: true }
            };
            let r = await this.$rest.get(getOptions);
            if (!r.data.doc) r.data.doc = r.data.data[0]
            this.totalRows = parseInt(r.data.doc.total)
          // }
        } 
        catch(e){
          console.error(e)
          this.totalRows = null
        }
        resolve()
      })
    },

    setPage(page){
      if (page == this.page) return
      this.loading = true;
      this.offset = (page - 1) * this.getLimit;
      this.page = page;
      this.getData()
    },
    
    async getData(cleanFilters = false) {

      try {
        this.loading = true;
        let url = this.url;
        let search
        if(cleanFilters === false){
          search = this.apiversion == "1" ? await this.$refs.filters.getFiltersV1() : await this.$refs.filters.getFiltersV2();
        } else{
          search = {};
        }
        
        let query = {...search};
        query.limit = this.getLimitByDropDown === null ? this.getLimit : this.getLimitByDropDown;
        query.offset = this.offset;
        if (this.order) query.order = this.order
      
        let getOptions = {
          url: url,
          params: query
        };
       
        let r = await this.$rest.get(getOptions);
        
        let data = r.data.data;
        //Le ponemos un 'index' personalizado a cada item de la 'data'. Porque el index de la tabla no nos sirve
        for (let i in data) {
          data[i].index = parseInt(i);
        }
        this.data = data;
        this.lasturl = r.request.responseURL
        //trae el total de registros
        let backToTheFirstPage = false;
        
        //possible first time using a filter
        if(this.lastfilter === '{"search":"{}"}') backToTheFirstPage = true;
        
        this.getTotalRows(search, cleanFilters)
        
        this.loading = false;

        if(backToTheFirstPage){
          if(search.search ===  "{}"){
            //it's no first time using a filter
            backToTheFirstPage = false;
          }
        }
        
        if(cleanFilters)  backToTheFirstPage = true;
        
        if(backToTheFirstPage) await document.getElementById('DTid').childNodes[16].childNodes[1].click(); 
        
        this.err = false;
      } 
      catch (e) {
        console.error(e);
        this.loading = false;
      }
    },
    
    downloadXls() {
      if (this.search == "") {
        window.location.assign(`${this.lasturl}?getXLS`);
      } else {
        window.location.assign(`${this.lasturl}&getXLS`);
      }
    },
    removeOffset(url) {
      let urlCopy = {...url};
      urlCopy = Object.values(urlCopy);
      urlCopy = urlCopy.join('');
      const startIndex = urlCopy.indexOf("offset=");
      let endIndex = urlCopy.indexOf("&", startIndex + 1);
      if (startIndex !== -1 && endIndex !== -1) {
        urlCopy = urlCopy.slice(0, startIndex) + urlCopy.slice(endIndex + 1);
      } 
      return urlCopy;
    },
  },
  props: {
    url: {
      type: String, //string que se usará para el http request
    },
    getLimit: {
      type: Number,
    },
    order: {
      type: String,
    },
  },

};