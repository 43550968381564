<template>
    <div v-if="formulario == getActuationName('denuncia') || formulario == getActuationName('oficio')">
        <p class="titulos text-center" >Datos de la Denuncia</p>

        <p class="titulosDatos" v-bind:title="data.caratula" @click="loadVaria(' @@Caratula@@ ')" id="p-caratula" test-id="p-caratula">Causa/Caratula</p>

        <p class="titulosDatos" v-bind:title="data.numeroSumario" @click="loadVaria(' @@Numero_de_Sumario@@ ')" id="p-numero-sumario" test-id="p-numero-sumario">Numero de Sumario</p>

        <p class="titulosDatos" v-bind:title="data.relatoDenuncia" @click="loadVaria(' @@Relato_de_la_Denuncia@@ ')" id="p-relato-denuncia" test-id="p-relato-denuncia">Relato de la Denuncia</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[0]" @click="loadVaria(' @@Fecha_de_la_Denuncia@@ ')" id="p-fecha-denuncia" test-id="p-fecha-denuncia">Fecha de la Denuncia</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[1]" @click="loadVaria(' @@Fec_de_la_Denun_2@@ ')" id="p-fecha-denuncia2" test-id="p-fecha-denuncia2">Fecha de la Denuncia 2</p>


        <p class="titulosDatos" v-bind:title="data.juzgado" @click="loadVaria(' @@Juzgado_Interviniente@@ ')" id="p-juzgado" test-id="p-juzgado">Juzgado Interviniente</p>

        <p class="titulosDatos" v-bind:title="data.año" @click="loadVaria(' @@Year@@ ')" id="p-año" test-id="p-año">Año de la Denuncia</p>

        <p class="titulosDatos" v-bind:title="data.horaDeExtCertificado" @click="loadVaria(' @@Hora_de_Creacion@@ ')" id="p-hora-documento" test-id="p-hora-documento">Hora de Creacion del Documento</p>

        <p class="titulosDatos" v-bind:title="data.efectosDenunciados" @click="loadVaria(' @@EfectosDenunciados@@ ')" id="p-efectos-denunciados" test-id="p-efectos-denunciados">Efectos Denunciados</p>

        <p v-if="formulario !== 'Sumario Prevencional Por Denuncia'" class="titulosDatos" v-bind:title="data.personalInterviniente" @click="loadVaria(' @@Personal_Interviniente@@ ')" id="personal-interviniente" test-id="personal-interviniente">Personal Interviniente</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[1]" @click="loadVaria(' @@Fec_de_la_Denun_2@@ ')" id="p-fecha-denuncia2" test-id="p-fecha-denuncia2">Fecha creación Documento</p>
        
        <p class="titulosDatos" v-bind:title="data.horaDeExtCertificado" @click="loadVaria(' @@Hora_de_Creacion@@ ')" id="p-hora-documento" test-id="p-hora-documento">Hora creación del Documento</p>

        <p class="titulos text-center" >Datos de la Dependencia</p>

        <p class="titulosDatos" v-bind:title="data.dependencia" @click="loadVaria(' @@Dependencian_Nombre@@ ')" id="p-dependencia" test-id="p-dependencia">Nombre Dependencia</p>

        <p class="titulosDatos" v-bind:title="data.departamento" @click="loadVaria(' @@Dependencia_Departamento@@ ')" id="p-departamento" test-id="p-departamento">Departamento</p>
        
        <p class="titulosDatos" v-bind:title="data.dependenciaNumero" @click="loadVaria(' @@Dependencia_Numero@@ ')" id="p-numero-dependencia" test-id="p-numero-dependencia">Numero de Dependencia</p>



        <p class="titulos text-center">Datos del Denunciante/Damnificado</p>

        <p class="titulosDatos" v-bind:title="data.nombreDenunciante" @click="loadVaria(' @@NombreDamnificado@@ ')" id="p-nombre" test-id="p-nombre">Nombre</p>
 
        <p class="titulosDatos"  v-bind:title="data.apellidoDenunciante" @click="loadVaria(' @@ApellidoDamnificado@@ ')" id="p-apellido" test-id="p-apellido">Apellido</p>

        <p class="titulosDatos" v-bind:title="data.denunciantesDni" @click="loadVaria(' @@dniDamnificado@@ ')" id="p-dni" test-id="p-dni">DNI</p>

        <p class="titulosDatos" v-bind:title="data.edadDenunciante" @click="loadVaria(' @@EdadDamnificado@@ ')" id="p-edad" test-id="p-edad">Edad</p>

        <p class="titulosDatos" v-bind:title="data.nacionalidadDenunciante" @click="loadVaria(' @@NacionalidadDamnificado@@ ')" id="p-nacionalidad" test-id="p-nacionalidad">Nacionalidad</p>

        <p class="titulosDatos" v-bind:title="data.estadoCivilDenunciante" @click="loadVaria(' @@EstadoCivilDamnificado@@ ')" id="p-estado-civil" test-id="p-estado-civil">Estado Civil</p>

        <p class="titulosDatos" v-bind:title="data.lugarDeRecidenciaDenunciante" @click="loadVaria(' @@DomicilioDamnificado@@ ')" id="p-domicilio" test-id="p-domicilio">Domicilio</p>  

        <p class="titulosDatos" v-bind:title="data.instruccion" @click="loadVaria(' @@instruccionDamnificado@@ ')" id="p-instruccion" test-id="p-instruccion">instruccion</p>

        <p class="titulosDatos" v-bind:title="data.telefonoDenunciante" @click="loadVaria(' @@TelefonoDamnificado@@ ')" id="p-telefono" test-id="p-telefono">Telefono</p>

        <p class="titulosDatos" v-bind:title="data.profesion" @click="loadVaria(' @@ProfesionDamnificado@@ ')" id="p-profesion" test-id="p-profesion">Profesion</p>

        <p class="titulosDatos" v-bind:title="data.denunciantes" @click="loadVaria(' @@DamnificadosText2@@ ')" id="p-damnificado-text2" test-id="p-damnificado-text2">Datos Completos</p>

        <p class="titulosDatos" v-bind:title="data.denunciantesCaratula" @click="loadVaria(' @@DamnificadosText@@ ')" id="p-damnificado-text" test-id="p-damnificado-text">Datos Completos Con Negrita</p>

        <p class="titulosDatos" v-bind:title="data.denuncianteDatosParciles" @click="loadVaria(' @@DamnificadosDatosParciales@@ ')" id="p-damnificado-DatosParciales" test-id="p-damnificado-DatosParciales">Datos Parciales</p>






        <p class="titulos text-center">Datos Del Hecho </p>
        
        <p class="titulosDatos" v-bind:title="data.lugarDelHecho" @click="loadVaria(' @@Lugar_del_Hecho@@ ')" id="p-lugar" test-id="p-lugar">Lugar del Hecho</p>

        <p class="titulosDatos" v-bind:title="data.horaDelHecho" @click="loadVaria(' @@Hora_del_Hecho@@ ')" id="p-hora" test-id="p-hora">Hora del Hecho</p>

        <p class="titulosDatos" v-bind:title="data.fechaDelHecho" @click="loadVaria(' @@Fecha_del_Hecho@@ ')" id="p-fecha" test-id="p-fecha">Fecha del Hecho</p>



        <p class="titulos text-center" >Datos del/los Acusado/s </p>

        <p  class="titulosDatos" v-bind:title="data.acusados" @click="loadVaria(' @@Acusados@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Datos Completos</p>
        
        <p  class="titulosDatos" v-bind:title="data.acusadosCaratula" @click="loadVaria(' @@AcusadosCaratula@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Datos Completos con Negrita</p>

        <p class="titulosDatos" v-bind:title="data.Apodo" @click="loadVaria(' @@Apodo@@ ')" id="p-Apodoes" test-id="p-Apodo">Apodo</p>

        <p class="titulosDatos" v-bind:title="data.acusadosDatosParciales" @click="loadVaria(' @@AcusadosDatosParciales@@ ')" id="p-acusados-datosParciales" test-id="p-acusados-datosParciales">Datos Parciales</p>
       
        <p class="titulosDatos" v-bind:title="data.efectosDenunciados" @click="loadVaria(' @@ObjetosSustraidos@@ ')" id="p-efectos-sustraidos" test-id="p-efectos-sustraidos">Objetos Sustraidos</p>

    </div>

    <div v-else-if="formulario == getActuationName('contravencional-oficio') || formulario == getActuationName('contravencional-denuncia')">
        <p class="titulos text-center" >Datos de la Denuncia</p>

        <p class="titulosDatos" v-bind:title="data.numeroSumario" @click="loadVaria(' @@Numero_de_Sumario@@ ')" id="p-numero-sumario" test-id="p-numero-sumario">Numero de Expediente</p>
        <p class="titulosDatos" v-bind:title="data.relatoDenuncia" @click="loadVaria(' @@Relato_de_la_Denuncia@@ ')" id="p-relato-denuncia" test-id="p-relato-denuncia">Relato</p>
        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[0]" @click="loadVaria(' @@Fecha_de_la_Denuncia@@ ')" id="p-fecha-denuncia" test-id="p-fecha-denuncia">Fecha de emision del Certificado</p>
        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[1]" @click="loadVaria(' @@Fec_de_la_Denun_2@@ ')" id="p-fecha-denuncia2" test-id="p-fecha-denuncia2">Fecha 2 de emision del Certificado</p>
        <p class="titulosDatos" v-bind:title="data.juzgado" @click="loadVaria(' @@Juzgado_Interviniente@@ ')" id="p-juzgado" test-id="p-juzgado">Juzgado Interviniente</p>
        <p class="titulosDatos" v-bind:title="data.año" @click="loadVaria(' @@Year@@ ')" id="p-año" test-id="p-año">Año de emision del Certificado</p>
        <p class="titulosDatos" v-bind:title="data.articulo" @click="loadVaria(' @@Articulo@@ ')" id="p-articulo" test-id="p-articulo">Articulos</p>
        <p class="titulosDatos" v-bind:title="data.horaDeExtCertificado" @click="loadVaria(' @@Hora_de_Creacion@@ ')" id="p-hora-documento" test-id="p-hora-documento">Hora de Creacion del Documento</p>

        <p v-if="formulario == getActuationName('contravencional-oficio')" class="titulosDatos" v-bind:title="data.personalInterviniente" @click="loadVaria(' @@Personal_Interviniente@@ ')" id="personal-interviniente" test-id="personal-interviniente">Personal Interviniente</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[1]" @click="loadVaria(' @@Fec_de_la_Denun_2@@ ')" id="p-fecha-denuncia2" test-id="p-fecha-denuncia2">Fecha creación Documento</p>
        
        <p class="titulosDatos" v-bind:title="data.horaDeExtCertificado" @click="loadVaria(' @@Hora_de_Creacion@@ ')" id="p-hora-documento" test-id="p-hora-documento">Hora creación del Documento</p>

        <p class="titulos text-center" >Datos de la Dependencia</p>

        <p class="titulosDatos" v-bind:title="data.dependencia" @click="loadVaria(' @@Dependencian_Nombre@@ ')" id="p-dependencia" test-id="p-dependencia">Nombre Dependencia</p>
        <p class="titulosDatos" v-bind:title="data.departamento" @click="loadVaria(' @@Dependencia_Departamento@@ ')" id="p-departamento" test-id="p-departamento">Departamento</p>
        <p class="titulosDatos" v-bind:title="data.dependenciaNumero" @click="loadVaria(' @@Dependencia_Numero@@ ')" id="p-numero-dependencia" test-id="p-numero-dependencia">Numero de Dependencia</p>
        <p class="titulosDatos" v-bind:title="data.dependenciaDomicilio" @click="loadVaria(' @@Domicilio_Dependencia@@ ')" id="p-numero-dependencia" test-id="p-numero-dependencia">Domicilio Dependencia</p>


        <p class="titulos text-center">Datos del Damnificado</p>

        <p class="titulosDatos" v-bind:title="data.nombreDenunciante" @click="loadVaria(' @@NombreDamnificado@@ ')" id="p-nombre" test-id="p-nombre">Nombre</p>
        <p class="titulosDatos"  v-bind:title="data.apellidoDenunciante" @click="loadVaria(' @@ApellidoDamnificado@@ ')" id="p-apellido" test-id="p-apellido">Apellido</p>
        <p class="titulosDatos" v-bind:title="data.denunciantesDni" @click="loadVaria(' @@dniDamnificado@@ ')" id="p-dni" test-id="p-dni">DNI</p>
        <p class="titulosDatos" v-bind:title="data.edadDenunciante" @click="loadVaria(' @@EdadDamnificado@@ ')" id="p-edad" test-id="p-edad">Edad</p>
        <p class="titulosDatos" v-bind:title="data.nacionalidadDenunciante" @click="loadVaria(' @@NacionalidadDamnificado@@ ')" id="p-nacionalidad" test-id="p-nacionalidad">Nacionalidad</p>
        <p class="titulosDatos" v-bind:title="data.estadoCivilDenunciante" @click="loadVaria(' @@EstadoCivilDamnificado@@ ')" id="p-estado-civil" test-id="p-estado-civil">Estado Civil</p>
        <p class="titulosDatos" v-bind:title="data.lugarDeRecidenciaDenunciante" @click="loadVaria(' @@DomicilioDamnificado@@ ')" id="p-domicilio" test-id="p-domicilio">Domicilio</p>  
        <p class="titulosDatos" v-bind:title="data.instruccion" @click="loadVaria(' @@instruccionDamnificado@@ ')" id="p-instruccion" test-id="p-instruccion">instruccion</p>
        <p class="titulosDatos" v-bind:title="data.telefonoDenunciante" @click="loadVaria(' @@TelefonoDamnificado@@ ')" id="p-telefono" test-id="p-telefono">Telefono</p>
        <p class="titulosDatos" v-bind:title="data.profesion" @click="loadVaria(' @@ProfesionDamnificado@@ ')" id="p-profesion" test-id="p-profesion">Profesion</p>
        <p class="titulosDatos" v-bind:title="data.denunciantes" @click="loadVaria(' @@DamnificadosText2@@ ')" id="p-damnificado-text2" test-id="p-damnificado-text2">Datos Completos</p>
        <p class="titulosDatos" v-bind:title="data.denunciantesCaratula" @click="loadVaria(' @@DamnificadosText@@ ')" id="p-damnificado-text" test-id="p-damnificado-text">Datos Completos con Negrita</p>
        <p class="titulosDatos" v-bind:title="data.denuncianteDatosParciles" @click="loadVaria(' @@DamnificadosDatosParciales@@ ')" id="p-damnificado-DatosParciales" test-id="p-damnificado-DatosParciales">Datos Parciales</p>



        <p class="titulos text-center">Datos Del Hecho </p>
        
        <p class="titulosDatos" v-bind:title="data.lugarDelHecho" @click="loadVaria(' @@Lugar_del_Hecho@@ ')" id="p-lugar" test-id="p-lugar">Lugar del Hecho</p>
        <p class="titulosDatos" v-bind:title="data.horaDelHecho" @click="loadVaria(' @@Hora_del_Hecho@@ ')" id="p-hora" test-id="p-hora">Hora del Hecho</p>
        <p class="titulosDatos" v-bind:title="data.fechaDelHecho" @click="loadVaria(' @@Fecha_del_Hecho@@ ')" id="p-fecha" test-id="p-fecha">Fecha del Hecho</p>



        <p class="titulos text-center" >Datos del/los Contraventor/res </p>

        <p  class="titulosDatos" v-bind:title="data.acusados" @click="loadVaria(' @@Acusados@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Datos Completos</p>
        <p  class="titulosDatos" v-bind:title="data.acusadosCaratula" @click="loadVaria(' @@AcusadosCaratula@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Datos Completos con Negrita</p>
        <p class="titulosDatos" v-bind:title="data.acusadosDatosParciales" @click="loadVaria(' @@AcusadosDatosParciales@@ ')" id="p-acusados-datosParciales" test-id="p-acusados-datosParciales">Datos Parciales</p>
        <p class="titulosDatos" v-bind:title="data.Apodo" @click="loadVaria(' @@Apodo@@ ')" id="p-Apodoes" test-id="p-Apodo">Apodo</p>


        <p  class="titulosDatos" v-bind:title="data.domicilioContraventor" @click="loadVaria(' @@DomicilioContraventor@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Domicilio</p>
        <p class="titulosDatos" v-bind:title="data.ApellidoContraventor" @click="loadVaria(' @@ApellidoContraventor@@ ')" id="p-ApellidoContraventor" test-id="p-ApellidoContraventor">Apellido</p>
        <p class="titulosDatos" v-bind:title="data.NombreContraventor" @click="loadVaria(' @@NombreContraventor@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Nombre</p>
        
    </div>    
    <div v-else-if="searchCoincidenceWithWord('ufi')">
        <p class="titulos text-center" >DATOS DE LA UFI</p>

        <p class="titulosDatos" v-bind:title="data.SumarioUfi" @click="loadVaria(' @@Numero de Ufi@@ ')" id="p-numero-ufi" test-id="p-numero-ufi">UFI N°</p>

        <!-- Dato especifico de Ufi Flagrancia -->
        <p class="titulosDatos" v-bind:title="data.caratula" @click="loadVaria(' @@Delito@@ ')" id="p-Delito" test-id="p-Delito">DELITO</p>

        <p class="titulosDatos" v-bind:title="data.numeroSumario" @click="loadVaria(' @@Numero_de_Sumario@@ ')" id="p-numero-sumario" test-id="p-numero-sumario">LEGAJO N°</p>

        <p class="titulosDatos" v-bind:title="data.prosecutor" @click="loadVaria(' @@Fiscal_a_cargo@@ ')" id="fiscal-a-cargo" test-id="fiscal-a-cargo">FISCAL A CARGO</p>
       
        <p class="titulosDatos" v-bind:title="data.assistant" @click="loadVaria(' @@Ayudante_fiscal@@ ')" id="ayudante-fiscal" test-id="ayudante-fiscal">AYUDANTE FISCAL</p>

        <p class="titulosDatos" v-bind:title="data.relatoDenuncia" @click="loadVaria(' @@Relato_de_la_Denuncia@@ ')" id="p-relato-denuncia" test-id="p-relato-denuncia">RELATO DE LA UFI</p>

        <!-- <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[0]" @click="loadVaria(' @@Fecha_de_la_Denuncia@@ ')" id="p-fecha-denuncia" test-id="p-fecha-denuncia">FECHA DE LA UFI</p> -->

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[1]" @click="loadVaria(' @@Fec_de_la_Denun_2@@ ')" id="p-fecha-denuncia2" test-id="p-fecha-denuncia2">FECHA DE LA UFI</p>



        <p class="titulosDatos" v-bind:title="data.año" @click="loadVaria(' @@Year@@ ')" id="p-año" test-id="p-año">AÑO DE LA UFI</p>
        
        <p class="titulosDatos" v-bind:title="data.horaDeExtCertificado" @click="loadVaria(' @@Hora_de_Creacion@@ ')" id="p-hora-documento" test-id="p-hora-documento">HORA DE CREACIÓN DE LA UFI</p>

        <!-- <p class="titulosDatos" v-bind:title="data.personalInterviniente" @click="loadVaria(' @@Personal_Interviniente@@ ')" id="personal-interviniente" test-id="personal-interviniente">Personal Interviniente</p> -->

        <p class="titulosDatos" v-bind:title="data.efectosDenunciados" @click="loadVaria(' @@ObjetosSustraidos@@ ')" id="p-efectos-sustraidos" test-id="p-efectos-sustraidos">EFECTOS SECUESTRADOS</p>

        <p class="titulosDatos" v-bind:title="data.personalInterviniente" @click="loadVaria(' @@Personal_Interviniente@@ ')" id="personal-interviniente" test-id="personal-interviniente">PERSONAL INTERVINIENTE</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[0]" @click="loadVaria(' @@Fecha_de_la_Denuncia@@ ')" id="p-fecha-denuncia" test-id="p-fecha-denuncia">FECHA DE LA DENUNCIA</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[1]" @click="loadVaria(' @@Fec_de_la_Denun_2@@ ')" id="p-fecha-denuncia2" test-id="p-fecha-denuncia2">FECHA CREACIÓN DOCUMENTO</p>
        
        <p class="titulosDatos" v-bind:title="data.horaDeExtCertificado" @click="loadVaria(' @@Hora_de_Creacion@@ ')" id="p-hora-documento" test-id="p-hora-documento">HORA CREACIÓN DEL DOCUMENTO</p>

        <p class="titulos text-center" >DATOS DE LA DEPENDENCIA</p>

        <p class="titulosDatos" v-bind:title="data.dependencia" @click="loadVaria(' @@Dependencian_Nombre@@ ')" id="p-dependencia" test-id="p-dependencia">NOMBRE DE LA DEPENDENCIA</p>

        <p class="titulosDatos" v-bind:title="data.departamento" @click="loadVaria(' @@Dependencia_Departamento@@ ')" id="p-departamento" test-id="p-departamento">DEPARTAMENTO</p>
        
        <p class="titulosDatos" v-bind:title="data.dependenciaNumero" @click="loadVaria(' @@Dependencia_Numero@@ ')" id="p-numero-dependencia" test-id="p-numero-dependencia">NÚMERO DE DEPENDENCIA</p>

        <!-- <p class="titulosDatos" v-bind:title="data.dependenciaDomicilio" @click="loadVaria(' @@Domicilio_Dependencia@@ ')" id="p-numero-dependencia" test-id="p-numero-dependencia">Domicilio Dependencia</p> -->


        <p class="titulos text-center">DATOS DE LA VÍCTIMA</p>

        <p class="titulosDatos" v-bind:title="data.nombreDenunciante" @click="loadVaria(' @@NombreDamnificado@@ ')" id="p-nombre" test-id="p-nombre">NOMBRE</p>
 
        <p class="titulosDatos"  v-bind:title="data.apellidoDenunciante" @click="loadVaria(' @@ApellidoDamnificado@@ ')" id="p-apellido" test-id="p-apellido">APELLIDO</p>

        <p class="titulosDatos" v-bind:title="data.denunciantesDni" @click="loadVaria(' @@dniDamnificado@@ ')" id="p-dni" test-id="p-dni">DNI</p>

        <p class="titulosDatos" v-bind:title="data.edadDenunciante" @click="loadVaria(' @@EdadDamnificado@@ ')" id="p-edad" test-id="p-edad">EDAD</p>

        <p class="titulosDatos" v-bind:title="data.nacionalidadDenunciante" @click="loadVaria(' @@NacionalidadDamnificado@@ ')" id="p-nacionalidad" test-id="p-nacionalidad">NACIONALIDAD</p>

        <p class="titulosDatos" v-bind:title="data.estadoCivilDenunciante" @click="loadVaria(' @@EstadoCivilDamnificado@@ ')" id="p-estado-civil" test-id="p-estado-civil">ESTADO CIVIL</p>

        <p class="titulosDatos" v-bind:title="data.lugarDeRecidenciaDenunciante" @click="loadVaria(' @@DomicilioDamnificado@@ ')" id="p-domicilio" test-id="p-domicilio">DOMICILIO</p>  

        <p class="titulosDatos" v-bind:title="data.instruccion" @click="loadVaria(' @@instruccionDamnificado@@ ')" id="p-instruccion" test-id="p-instruccion">INSTRUCCION</p>

        <p class="titulosDatos" v-bind:title="data.telefonoDenunciante" @click="loadVaria(' @@TelefonoDamnificado@@ ')" id="p-telefono" test-id="p-telefono">TELEFONO</p>

        <p class="titulosDatos" v-bind:title="data.profesion" @click="loadVaria(' @@ProfesionDamnificado@@ ')" id="p-profesion" test-id="p-profesion">PROFESION</p>

        <p class="titulosDatos" v-bind:title="data.denunciantes" @click="loadVaria(' @@DamnificadosText2@@ ')" id="p-damnificado-text2" test-id="p-damnificado-text2">DATOS COMPLETOS</p>

        <p class="titulosDatos" v-bind:title="data.denunciantesCaratula" @click="loadVaria(' @@DamnificadosText@@ ')" id="p-damnificado-text" test-id="p-damnificado-text">DATOS COMPLETOS NEGRITA</p>

        <p class="titulosDatos" v-bind:title="data.denuncianteDatosParciles" @click="loadVaria(' @@DamnificadosDatosParciales@@ ')" id="p-damnificado-DatosParciales" test-id="p-damnificado-DatosParciales">DATOS PARCIALES</p>




        <p class="titulos text-center">DATOS DEL HECHO</p>
        
        <p class="titulosDatos" v-bind:title="data.lugarDelHecho" @click="loadVaria(' @@Lugar_del_Hecho@@ ')" id="p-lugar" test-id="p-lugar">LUGAR DEL HECHO</p>

        <p class="titulosDatos" v-bind:title="data.horaDelHecho" @click="loadVaria(' @@Hora_del_Hecho@@ ')" id="p-hora" test-id="p-hora">HORA DEL HECHO</p>

        <p class="titulosDatos" v-bind:title="data.fechaDelHecho" @click="loadVaria(' @@Fecha_del_Hecho@@ ')" id="p-fecha" test-id="p-fecha">FECHA DEL HECHO</p>



        <p class="titulos text-center" >DATOS DEL APREHENDIDO</p>

        <p  class="titulosDatos" v-bind:title="data.acusados" @click="loadVaria(' @@Acusados@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">DATOS COMPLETOS</p>
        <p  class="titulosDatos" v-bind:title="data.acusadosCaratula" @click="loadVaria(' @@AcusadosCaratula@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">DATOS COMPLETOS NEGRITA</p>
        <p class="titulosDatos" v-bind:title="data.Apodo" @click="loadVaria(' @@Apodo@@ ')" id="p-Apodoes" test-id="p-Apodo">APODO</p>
        <p class="titulosDatos" v-bind:title="data.acusadosDatosParciales" @click="loadVaria(' @@AcusadosDatosParciales@@ ')" id="p-acusados-datosParciales" test-id="p-acusados-datosParciales">DATOS PARCIALES</p>

        
    </div>    
    <div v-else-if="searchCoincidenceWithWord('preliminar')">
        <p class="titulos text-center" >Datos de la Denuncia</p>

        <p class="titulosDatos" v-bind:title="data.numeroSumario" @click="loadVaria(' @@Numero_de_Sumario@@ ')" id="p-numero-sumario" test-id="p-numero-sumario">Numero de Actuaciones Preliminares</p>
        
        <p class="titulosDatos" v-bind:title="data.relatoDenuncia" @click="loadVaria(' @@Relato_de_la_Denuncia@@ ')" id="p-relato-denuncia" test-id="p-relato-denuncia">Relato</p>

        <p class="titulosDatos" v-bind:title="data.juzgado" @click="loadVaria(' @@Interviniente@@ ')" id="p-juzgado" test-id="p-juzgado">Interviniente</p>

        <p class="titulosDatos" v-bind:title="data.año" @click="loadVaria(' @@Year@@ ')" id="p-año" test-id="p-año">Año de emision del Certificado</p>

        <p class="titulosDatos" v-bind:title="data.caratula" @click="loadVaria(' @@Caratula@@ ')" id="p-caratula" test-id="p-caratula">Causa/Caratula</p>

        <p class="titulosDatos" v-bind:title="data.personalInterviniente" @click="loadVaria(' @@Personal_Interviniente@@ ')" id="personal-interviniente" test-id="personal-interviniente">Personal Interviniente</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[0]" @click="loadVaria(' @@Fecha_de_la_Denuncia@@ ')" id="p-fecha-denuncia" test-id="p-fecha-denuncia">Fecha de la Denuncia</p>

        <p class="titulosDatos" v-bind:title="data.fechaDeExtencionCertificadoText[1]" @click="loadVaria(' @@Fec_de_la_Denun_2@@ ')" id="p-fecha-denuncia2" test-id="p-fecha-denuncia2">Fecha creación Documento</p>
        
        <p class="titulosDatos" v-bind:title="data.horaDeExtCertificado" @click="loadVaria(' @@Hora_de_Creacion@@ ')" id="p-hora-documento" test-id="p-hora-documento">Hora creación del Documento</p>


        <p class="titulos text-center" >Datos de la Dependencia</p>

        <p class="titulosDatos" v-bind:title="data.dependencia" @click="loadVaria(' @@Dependencian_Nombre@@ ')" id="p-dependencia" test-id="p-dependencia">Nombre Dependencia</p>
        <p class="titulosDatos" v-bind:title="data.departamento" @click="loadVaria(' @@Dependencia_Departamento@@ ')" id="p-departamento" test-id="p-departamento">Departamento</p>
        <p class="titulosDatos" v-bind:title="data.dependenciaNumero" @click="loadVaria(' @@Dependencia_Numero@@ ')" id="p-numero-dependencia" test-id="p-numero-dependencia">Numero de Dependencia</p>
        <p class="titulosDatos" v-bind:title="data.dependenciaDomicilio" @click="loadVaria(' @@Domicilio_Dependencia@@ ')" id="p-numero-dependencia" test-id="p-numero-dependencia">Domicilio Dependencia</p>


        <p class="titulos text-center">Datos del Damnificado y/o Denunciante</p>

        <p class="titulosDatos" v-bind:title="data.nombreDenunciante" @click="loadVaria(' @@NombreDamnificado@@ ')" id="p-nombre" test-id="p-nombre">Nombre</p>
        <p class="titulosDatos"  v-bind:title="data.apellidoDenunciante" @click="loadVaria(' @@ApellidoDamnificado@@ ')" id="p-apellido" test-id="p-apellido">Apellido</p>
        <p class="titulosDatos" v-bind:title="data.denunciantesDni" @click="loadVaria(' @@dniDamnificado@@ ')" id="p-dni" test-id="p-dni">DNI</p>
        <p class="titulosDatos" v-bind:title="data.edadDenunciante" @click="loadVaria(' @@EdadDamnificado@@ ')" id="p-edad" test-id="p-edad">Edad</p>
        <p class="titulosDatos" v-bind:title="data.nacionalidadDenunciante" @click="loadVaria(' @@NacionalidadDamnificado@@ ')" id="p-nacionalidad" test-id="p-nacionalidad">Nacionalidad</p>
        <p class="titulosDatos" v-bind:title="data.estadoCivilDenunciante" @click="loadVaria(' @@EstadoCivilDamnificado@@ ')" id="p-estado-civil" test-id="p-estado-civil">Estado Civil</p>
        <p class="titulosDatos" v-bind:title="data.lugarDeRecidenciaDenunciante" @click="loadVaria(' @@DomicilioDamnificado@@ ')" id="p-domicilio" test-id="p-domicilio">Domicilio</p>  
        <p class="titulosDatos" v-bind:title="data.instruccion" @click="loadVaria(' @@instruccionDamnificado@@ ')" id="p-instruccion" test-id="p-instruccion">instruccion</p>
        <p class="titulosDatos" v-bind:title="data.telefonoDenunciante" @click="loadVaria(' @@TelefonoDamnificado@@ ')" id="p-telefono" test-id="p-telefono">Telefono</p>
        <p class="titulosDatos" v-bind:title="data.profesion" @click="loadVaria(' @@ProfesionDamnificado@@ ')" id="p-profesion" test-id="p-profesion">Profesion</p>
        <p class="titulosDatos" v-bind:title="data.denunciantes" @click="loadVaria(' @@DamnificadosText2@@ ')" id="p-damnificado-text2" test-id="p-damnificado-text2">Datos Completos</p>
        <p class="titulosDatos" v-bind:title="data.denunciantesCaratula" @click="loadVaria(' @@DamnificadosText@@ ')" id="p-damnificado-text" test-id="p-damnificado-text">Datos Completos con Negrita</p>
        <p class="titulosDatos" v-bind:title="data.denuncianteDatosParciles" @click="loadVaria(' @@DamnificadosDatosParciales@@ ')" id="p-damnificado-DatosParciales" test-id="p-damnificado-DatosParciales">Datos Parciales</p>

        <p class="titulos text-center">Datos Del Hecho</p>
        
        <p class="titulosDatos" v-bind:title="data.lugarDelHecho" @click="loadVaria(' @@Lugar_del_Hecho@@ ')" id="p-lugar" test-id="p-lugar">Lugar del Hecho</p>
        <p class="titulosDatos" v-bind:title="data.horaDelHecho" @click="loadVaria(' @@Hora_del_Hecho@@ ')" id="p-hora" test-id="p-hora">Hora del Hecho</p>
        <p class="titulosDatos" v-bind:title="data.fechaDelHecho" @click="loadVaria(' @@Fecha_del_Hecho@@ ')" id="p-fecha" test-id="p-fecha">Fecha del Hecho</p>

        <p class="titulos text-center" >Datos de los acusados y/o detenidos</p>

        <p  class="titulosDatos" v-bind:title="data.acusados" @click="loadVaria(' @@Acusados@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Datos Completos</p>
        <p  class="titulosDatos" v-bind:title="data.acusadosCaratula" @click="loadVaria(' @@AcusadosCaratula@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Datos Completos con Negrita</p>
        <p class="titulosDatos" v-bind:title="data.acusadosDatosParciales" @click="loadVaria(' @@AcusadosDatosParciales@@ ')" id="p-acusados-datosParciales" test-id="p-acusados-datosParciales">Datos Parciales</p>
        <p class="titulosDatos" v-bind:title="data.Apodo" @click="loadVaria(' @@Apodo@@ ')" id="p-Apodoes" test-id="p-Apodo">Apodo</p>

        <p  class="titulosDatos" v-bind:title="data.domicilioContraventor" @click="loadVaria(' @@DomicilioContraventor@@ ')" id="p-apellido-nombre-acusado" test-id="p-apellido-nombre-acusado">Domicilio</p>
        <p  class="titulosDatos" v-bind:title="data.ApellidoContraventor"  @click="loadVaria(' @@ApellidoContraventor@@ ')" id="p-ApellidoContraventor" test-id="p-ApellidoContraventor">Apellido</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@NombreContraventor@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Nombre</p>
        
      </div> 
      <div v-else-if="formulario.includes('Depósito Judicial')">    
        <p class="titulos text-center">DATOS OBJETO</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@CategoríaObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Categoría</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@SubcategoríaObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Subcategoría</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@MarcaObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Marca</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@ModeloObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Modelo</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@TipoObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Tipo</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@DatosPúblicosObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Datos públicos</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@DatosPrivadosObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Datos privados</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@AñoObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Año</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@NºMotorObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Nº Motor</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@NºChasisObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Nº Chasis</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@NºSerieObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Nº Serie</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@DominioObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Dominio</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@NºIMEIObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Nº IMEI</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@NºIMEIObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Nº Abonado</p>
        <p  class="titulosDatos" v-bind:title="data.NombreContraventor"    @click="loadVaria(' @@ColorObjeto@@ ')" id="p-NombreContraventor" test-id="p-NombreContraventor">Color</p>
      </div>    
</template>

<script>
// import EditorWord from '../components/EditorWord.vue'
import { mapState } from 'vuex'


export default {
  name: 'DatosNewDiligenciaSum',
    components: {
    // EditorWord,
  },
  props: {
    formulario: {
      type: String,
    },
  },
  data() { 
    return {
    }
  },
  // mounted(){
    // console.log('this.views', this.views);
    //  console.log('formulario.includes(UFI) || formulario.includes(ufi)', this.formulario.includes('UFI') || this.formulario.includes('ufi'));
  // },
  // watch: {
  //   formulario: function(newValue, oldValue) {
  //     // console.log('this.formulario', 'this.formulario' + this.formulario + 'this.formulario');
  //     console.log('oldValue', oldValue);
  //     console.log('newValue', newValue);
  //   }
  // },
  computed: {

    data: {
      get (){
        return this.$store.getters['sumario/dataResumen']
      }
    },
    ...mapState({
      views: (state) => state.sumarioViews,
    }),
  },
  methods: {

    loadVaria(dato){
      // console.log("CLICK!!!")
    this.$emit("click", dato);
    // this.$refs.editor.loadVariables(dato);
    },
    getActuationName(actuationRef){
      return Object.values(this.views[actuationRef].actuationName)[0];
    },
    searchCoincidenceWithWord(wordToSearch){
      let coincidencesWithProp = [];
      for (const prop in this.views) {
        if (prop.toLowerCase().includes(wordToSearch)) {
          coincidencesWithProp.push(Object.values(this.views[prop]?.actuationName || {})[0] === this.formulario);
        }
      }
      let matchWithCurrentForm = coincidencesWithProp.some(element => element === true);
      return matchWithCurrentForm;
    }
  },
}

</script>
            