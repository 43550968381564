import axios from 'axios'
import Vue from 'vue'


const methodPut = (options) => new Promise(async(resolve, reject) =>  {
  try {
    if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
    let params = options.params || {}
    let response = await axios.put(options.url, options.formData, { withCredentials: true, params }, {
      headers: {...options.headers }
    })
    return resolve(response);
  } catch (err) {
    console.log('err', err);
    Vue.prototype.$toast.open({ message: "Ocurrió un error al guardar una de las ediciones.", position: 'top-right', type: 'error', duration: 3000 });
    return reject(err);
  }
})

function replaceUndefinedWithNull(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] === undefined) {
      obj[key] = null;
    }
  }
  return obj;
}

function accessNestedProperties(structureToUnnests, objectToUpdate) {
  let newElement = {};
  for (let prop in structureToUnnests) {
    const objectToUnnest = structureToUnnests[prop];
    let payload = objectToUpdate.doc;
    for (let i = 0; i <  objectToUnnest.length; i++) {
      payload = payload?.[objectToUnnest[i]] ;
    }
    newElement[prop] = payload !== undefined ? payload : "";
  }
  return {...newElement};
}

async function getActuationFromBack(state) {
  let options = {
    url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${state.actuationDataForEdit.id}`,
    ContentType: "text/plain"
  }
  let response = await axios.get(options.url, { withCredentials: true, params }, {
    headers: {...options.headers }
  })
  return response.data.data[0];
}

export default {
  methodPut,
  replaceUndefinedWithNull,
  accessNestedProperties,
  getActuationFromBack,
}