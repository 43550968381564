<template>
  <div class="page">
    <div class="grid justify-content-between align-items-center vertical-container menu">

      <div class="feature-intro mt-6">
          <h1>Crear Diligencia</h1>
      </div>
      <div :class="{'spinner':timer, 'ml-auto':true, 'md:col-1':true, 'mt-auto':true}" >
          <ProgressSpinner style="width:50px;height:50px" strokeWidth="5" fill="#EEEEEE" animationDuration="1.5s"/>        
      </div> 
      <div>
        <Button label="Preview" @click="seeDiligencia()" type="button" v-tooltip.left="'Vista Previa'"  icon="pi pi-search" class="p-button-success mr-2"  ></Button>

        <Button label="Guardar" @click="guardar()" class="p-button-warning mr-2" icon="pi pi-save" test-id="button-guardar" id="button-guardar" :disabled="guardarDisabled" />
      </div>
    </div>
          <SuccessAndFail
            :msjEnable.sync="msjEnable"
            :mensajeDialog="mensaje"
            :buttomAceptar="buttomAceptar"
            :icon.sync="iconModal"
      />

    <div class="grid mt-4">
      <div class="flex justify-content-between w100" >

        <div class="col-9" >

            <editor-word 
              ref="editor"  
              v-on:loading="loadingStatus"  
              class="letter"
              :enableFontSize="true"
              test-id="editorWord"
            />
            
          </div>

        <div  class="col-3 modelosList justify-content-center" style="max-height: 50em">

            <p class="titulos text-center" >Tipo de formulario</p>
            <div test-id="Autocomplete-selecForm">
               <Dropdown dataKey='Nombre' v-model="tipoFormulario" optionLabel="Nombre" :options="tiposSumariosList" optionValue="Nombre"  placeholder="Formulario" class="flex" :filter="true" />
            </div>

            <p class="titulos text-center" >Nombre de la Nueva diligencia</p>
            <div class="menuWord flex flex-column ">
              <span class="p-input-icon-left">
                  <InputText  class="w100" placeholder="Nombre De La Diligencia" id="input-nom-new-diligencia" test-id="input-nom-new-diligencia" v-model="datoInput"/>
              </span>
            </div>
            <p class="titulos text-center" >Tiempo limite para modificarla: {{TiempoLimite}} hs</p>
            <div class="menuWord flex flex-column ">
              <span class="p-input-icon-left">
                  <Slider  class="w100" :max="48" :min="0" placeholder="Nombre De La Diligencia" id="input-nom-new-diligencia" test-id="sliderNewDiligencia" v-model="TiempoLimite"/>
              </span>
            </div>
            <div class= "flex">
              <div class="col-6">
                <p class="titulos text-center" >Visibilidad</p>
                <SelectButton class="text-center"  v-model="visibilidad" :options="optionsvisibilidad" optionLabel="name"/>
              </div>
              <div class="col-6 text-center">
                <p class="titulos text-center" >Posición</p>
                <InputText  class="text-center" style="width: 65%;" placeholder="Numero de posición" id="input-pisicion-new-diligencia" test-id="input-pisicion-new-diligencia" v-model="posicion"/>
              </div>     
            </div>  
              <ScrollPanel style="width: 100%; height: 25em">     
                  <DatosNewDiligenciaSum @click="loadVar"  id="DatosNewDiligenciaSum" :formulario = formulario style="height: 80em"/><!-- :class="{ disable : formulario != 'Sumario Prevencional Por Denuncia' }"/>-->
              </ScrollPanel>
        </div>
      </div>
    </div>
    <div v-if="showPreview" class="print-page">
      <div class="menuprint flex justify-content-between align-items-center">
        <h1>Vista previa</h1>
        <Button icon="pi pi-times" @click="closePreview" class="p-button-rounded p-button-danger p-button-outlined" id="button-close" test-id="button-close"/>
      </div>
      <div v-if="loader" class="p-barra de progreso">
        <ProgressBar mode="indeterminate"/>
      </div>
      <iframe src="" frameborder="0" name="print_iframe"></iframe>
    </div>
  </div>
</template>


<script>

import EditorWord from '@/components/EditorWord/EditorWord.vue'
import DatosNewDiligenciaSum from '../components/DatosNewDiligenciaSum.vue'
import docLimpio from '@/documentos/limpio.js'

import { mapState } from 'vuex'

import InputText from 'primevue/inputtext';//estas son solo para unit test
import Button from 'primevue/button';
import AutoComplete from 'primevue/autocomplete';
import ScrollPanel from 'primevue/scrollpanel';
import axios from 'axios'

export default {

  name: 'DenunciaPrint',
  components: {
    EditorWord,
    DatosNewDiligenciaSum,
    InputText,Button,AutoComplete,//estas son solo para unit test
    ScrollPanel
  },
  data() { 
    return {
      buttomAceptar:{buttom:true,action:false},
      iconModal:{},
      mensaje:'',
      msjEnable:false,
      datosDenuncia: {},
      printDoc: {},
      docu: {},
      filteredFormulario: [],
      disabledFields: [],
      formulario: '',
      tiposSumariosList:[],
      visibleSumPrevDenun: false,
      limpio:'',
      err:false,
      datoInput:'',
      documento:'docu vacio',
      sumarios: [],
      idSumario: '',
      timer:true,
      showPreview: false,
      loader:false,
      formClass:'',
      visibilidad: {name: 'SI', value: "true"},
      optionsvisibilidad: [{name: 'SI', value: "true"}, {name:'NO', value: "false"}],
      tipoCamino:"Calle",
      posicion: '',
      TiempoLimite:0,
      guardarDisabled:false,
      idTipoDiligencia: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      viewData: (state) => state.sumarioViews,
    }),
    //     ...mapState({
    //   formularioList: state => state.siis.tiposModelosEscritos,
    // }),
    tipoFormulario:{
      get(){
        return this.formulario
      },
      set(value){
         this.formulario = value;
        this.selecFormulario()
        return
      }
    },
    abc:{
      get ()
      {
        return this.sumarios.data.data;       
      }
    },
    data: {
      get ()
      {
        return this.$store.getters['sumario/dataResumen']        
      }
    }
    ,
    // dataFormulario: {
    //   get () { 
    //       return this.formulario.Nombre 
    //     },
    //   set (v){ 
    //     console.log("esta v nos muestra que : "+v);
    //     return this.formulario = v}
    // },
  },
  async created(){
      // recuperamos el id e la url
      const { params: { id }} = this.$route;
      this.idTipoDiligencia = id
      switch(id)
      {
        case 'new':{
          this.printDoc = await import('../documentos/limpio')//docLimpio;//si usamos docLimpio no carga el documento
          this.docu = this.printDoc.default()
          this.$refs.editor.setCode(this.docu)
          this.limpio= await this.$refs.editor.docuHtml();//cargo limpio para chekear, en la creacion de nueva diligencia , si el documento esta vacio
          this.sumarios= await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-actuacion/`, { withCredentials: true });
          //Adding the "Depósito Judicial" option to "Tipo de formulario" 
          this.sumarios.data.data.push({
            Descripcion: null,
            Diasvencimiento: null,
            Fechafinvif: null,
            Fechainivig: "2023-01-27 12:01:00",
            Nombre: "Depósito Judicial",
            TiposumarioID: "Depósito Judicial"
          })
          //Quitamos Unidades Fiscales sin especializacion (no se quita de la BD directamente, por que la ausencia de este dato puede generar errores)
          this.tiposSumariosList = this.sumarios.data.data.filter(e =>e.Nombre != "Unidades Fiscales de Investigacion")
          //Adaptamos los nombres de las Actuaciones "UFIs" a los nombres pedidos
          for (const property in this.tiposSumariosList) {
            let nameFromViews = Object.values(this.viewData).find(item => item?.actuationName?.[this.tiposSumariosList[property]?.Nombre] !== undefined);
            if(nameFromViews !== undefined ){
              let nameFromViewsValue = nameFromViews.actuationName[this.tiposSumariosList[property].Nombre];
              this.tiposSumariosList[property].Nombre = nameFromViewsValue !== undefined ? nameFromViewsValue : this.tiposSumariosList[property].Nombre;
            }
          }
        }break
        default:{
          this.showPreview = true;
          this.loader = true;
          let respuesta = await this.getDiligencia(id)
          this.seeDiligencia(respuesta)
        }
      }
  },
  methods: {
    async getDiligencia(id){
      console.log(process.env.VUE_APP_BACKEND_ENVIRONMENT+"/parametros/tipo-diligencia/"+id)
      return new Promise(async resolve => {
        let r
        if (this.$offline.onlineMode){
          r = await this.$rest.get({url: process.env.VUE_APP_BACKEND_ENVIRONMENT+"/parametros/tipo-diligencia/"+id})
        } else {
          r = await this.$offline.getItem("tipo-diligencia", {DiligenciaID: id})
        }
        return resolve(r.data.data[0]);
      })
    },
    ////La idea de esta funcion es que el loading de la Vista Previa se pare cuanodo El componente EditWord avise que termino de proscesar
    ////El problema es que no logramos hacer que retorne despues de que termine la funcion de mostrar el pdf
    loadingStatus(status)
    {
          // console.log("Volvio y trajo: ",status)
          this.loader = status
          // this.formClass = status;
    },
    closePreview(){
      // this.$router.go(-1)
      this.showPreview = false
      if(this.idTipoDiligencia != 'new')
      {
        let nav=navigator.userAgent.toLowerCase()
        switch(nav){
          case "firefox" :
          this.$router.go(-2)
          case "chrome":
          this.$router.go(-1)
          default :
          this.$router.go(-1)
        }
      }
    },
    async seeDiligencia( r = false)
    {
          //activamos la vista previa de EditorWord
          this.showPreview = true;
          this.loader = true;
          if(r == false)
          {
            //traemos el documento cargado
            this.documento = await this.$refs.editor.docuHtml();
            let probando = this.documento.replace(/id="master-sangria"/g,'$id-master-sangria$') 
            // console.log("this.documento", this.documento )
  
            this.documento = this.addVariables(this.documento)
  

          }else
          {
                //Cargamos el documento en el editor word
                var txt = document.createElement("textarea");
                txt.innerHTML = r.Leyenda
                let codigo = txt.value;

                this.printDoc = await import('../documentos/limpio')//docLimpio;//si usamos docLimpio no carga el documento
                this.docu = this.printDoc.default()
                codigo = this.addVariables(codigo) 
                
                this.docu.html = codigo //le quitamos las comillas al string
                // console.log("Despues\n", this.docu.html)
                await this.$refs.editor.setCode(this.docu)// mostramos en el editor

                //traemos el documento cargado
                this.documento = await this.$refs.editor.docuHtml();
          }
          await this.$refs.editor.print(this.documento);
          setTimeout(()=>{
            this.loader = false
          }, 4300)
    },
    async guardar(){
      this.guardarDisabled=true;
      this.documento = await this.$refs.editor.docuHtml();//traemos el documento cargado
      const htmlEntities = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&apos;"
      };
      
      this.documento = this.documento.replace(/([&<>\"'])/g, match => htmlEntities[match])
      let TamanoSangria = this.$refs.editor.getSangria()
      var newNameDiligencia = this.datoInput;

      if( newNameDiligencia == ''){
        this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }             
        this.mensaje = 'El nombre ingresado no es valido'
        this.msjEnable = true
        this.guardarDisabled=false;
      }else if(this.limpio == this.documento)
        { 
          this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }             
          this.mensaje = "El documento que intenta guardar esta vacio"
          this.msjEnable = true
          this.guardarDisabled=false;
        }else  if(this.idSumario == '')
                {
                  this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }             
                  this.mensaje = "No selecciono ningun tipo de Formulario"
                  this.msjEnable = true
                  this.guardarDisabled=false;
                }else
                {
                  let NewDoc = JSON.stringify(this.documento)
                  // console.log("antesdocumento: \n"+NewDoc)
                  NewDoc = NewDoc.replace(/\\\//g, "");
                  let data= {
                        Descripcion: "",
                        Leyenda: NewDoc,
                        Nombre: newNameDiligencia,
                        CaratulaID: "ForeignKey",
                        FormatoID: "ForeignKey",
                        TiposumarioID: this.idSumario,
                        Visible: this.visibilidad.value,
                        TiempoLimite:this.TiempoLimite,
                        TamanoSangria
                    };
                    if(this.posicion){
                      data.Orden =this.posicion;
                    }
                    // console.log("documento despues: \n"+ data.Orden)
                    // console.log("data: \n"+JSON.stringify(data))
                    try
                    {
                      this.timer = false;
                      let bsq = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-diligencia/`, { withCredentials: true });
                      let aux = bsq.data.data.find(elem=>elem.Nombre == `${newNameDiligencia}` && elem.TiposumarioID == this.idSumario)
                      // console.log("aux: ", aux )
                      if(aux === undefined){

                        // console.log("user",JSON.stringify(this.user.userdata.dependenciaID))
                        let request = await axios.post( `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/parametros/tipo-diligencia/`,data, { withCredentials: true })
                        this.timer= true;
                        // console.log("request: ",JSON.stringify(request.data))
                        if(request.data.error == ''){
  
                          // alert(`La diligencia ${newNameDiligencia} fue Creada con exito`);
                          this.buttomAceptar= {buttom:true,action:false,routerPush:"/parametros/tipo-diligencia"}
                          this.iconModal = {type :'pi pi-check-circle', style : 'font-size: 3em; color: rgb(89, 150, 29);'}              
                          this.mensaje = `La diligencia ${newNameDiligencia} fue Creada con exito`
                          this.msjEnable = true

                        }

                      }else{
                        this.iconModal = {type :'pi pi-exclamation-circle', style : 'font-size: 3em; color: red;' }             
                        this.mensaje = `El Nombre ${newNameDiligencia} ya se encuentra registrado para el tipo de Actuacion ${this.idSumario}. Seleccione otro Nombre"`
                        this.msjEnable = true
                        this.timer = true;
                        this.guardarDisabled=false;
                        
                      }
                    }
                    catch(e)
                    {
                          this.mensaje = "La diligencia no pudo ser Creada. Error: ",e
                          this.msjEnable = true
                          this.buttomAceptar= {buttom:true,action:false}
                          this.guardarDisabled=false;
                    }
                }

    },

    loadVar(dato){
      console.log('dato', dato);
        this.$refs.editor.loadVariables(dato);
     },

    selecFormulario(){

      if(typeof(this.formulario) != 'object')//Esto lo hacemos ya que solo queremos se ejecute cuando el dato sea String, osea ingresado por teclado(de lo contrario los Object daran error por los woLowerCase)
      {
        let value =  this.abc.filter((item) => item.Nombre.toLowerCase().search(this.formulario.toLowerCase()) >= 0  );

        if(value[0] == undefined)
        {
          value[0] = ''
        }

        this.formulario = value[0].Nombre;
        this.idSumario = value[0].TipoActuacionID;
      }
    },

    filterFormulario(event) {

      this.filteredFormulario = this.abc.filter((item) => item.Nombre.toLowerCase().search(event.query.toLowerCase()) >= 0 );

    }, 

    isDisabled(field){
      if(typeof(this.formulario) == 'object')//Esto lo hacemos ya que solo queremos se ejecute cuando el dato sea String, osea ingresado por teclado(de lo contrario los Object daran error por los woLowerCase)
      {
        this.formulario = this.formulario.Nombre
        this.idSumario = this.formulario.TiposumarioID
      }
      return this.disabledFields.indexOf(field) >= 0
    },

    addVariables(str) {
      //Ajustes de Doc
      // console.log("ID:::::::::: ", this.idTipoDiligencia)
      if(this.idTipoDiligencia != 'new')
      {
        //Ajustes extra en caso de ser una edicion o un documento proveniente de la BD
        str = str.replace('"<', '<')
        str = str.replace('>"', '>')
        str = str.replace(/1.4em;/g, 'style="line-height: 1.40em;')
        str = str.replace(/1.5;/g, 'style="line-height: 1.50em;')
        str = str.replace(/1.38;/g, 'style="line-height: 1.38em;')
        str = str.replace(';;', ';')
  
        // // str = str.replace(/['center\">]+/g, 'center">')
        str = str.replace(/\\/g, "");
      }
      
      // // str = str.replace(/['center\">]+/g, 'center">')
      str = str.replace(/style="line-height: 1.4em; font-size: 1em; height:  auto; width:  auto; border:  0px solid; display:  block; padding:  auto auto auto auto; color:  black; margin:  1em auto auto auto;; text-indent:  0em; font-family: Times New Roman "/g, "");

      //Datos de la Denuncia
      str = str.replace(/@@Year@@/g,'2022');
      //Reenplazamos por data.caratula, en caso de que no exista, vemos si el nuevo tipo de datao "listaCaratulas" existe y lo cargamos
      str = str.replace(/@@Caratula@@/g, '@@ListaCaratulas@@');
      str = str.replace(/@@ListaCaratulas@@/g, 'ROBO AGRAVADO' );

      str = str.replace(/@@Numero_de_Sumario@@/g, '2020/03');
      str = str.replace(/@@Relato_de_la_Denuncia@@/g,  'Este es un ejemplo del relato de una denuncia, utilizado para mostrar como se veria el mismo en una diligencia'  );
      str = str.replace(/@@Fec_de_la_Denun_2@@/g, 'Lunes, 10 de Enero de 2022'  );
      str = str.replace(/@@Fecha_de_la_Denuncia@@/g, '10 dias del mes de Enero de 2022' );
      str = str.replace(/@@Hora_de_la_denuncia@@/g, '15:02' )      ;
      str = str.replace(/@@Juzgado_Interviniente@@/g, '1ER JUZGADO DE INSTRUCCION') ;  
      str = str.replace(/@@Articulo@@/g, 'Art 108° Discriminación - Ley N° 941-R');
      str = str.replace(/@@Personal_Interviniente@@/g, ' Sargento primero Juan Perez adscripto/s al numerario de Comisaria 6° Rawson' );
      str = str.replace(/@@Hora_de_Creacion@@/g, '14:12');
      str = str.replace(/@@EfectosDenunciados@@/g, 'Celular, Marca Motorola, Modelo g60s, Color Azul');
              //Datos de Ufi
      str = str.replace(/@@Numero de Ufi@@/g,  '4') ;
      str = str.replace(/@@Ayudante_fiscal@@/g, 'Dr. Adrian Camus');
      str = str.replace(/@@Fiscal_a_cargo@@/g, 'Dr. Solera Gustabo' );
      str = str.replace(/@@Delito@@/g, 'ROBO AGRAVADO' );

      //Datos propios de Preliminares
      str = str.replace(/@@Interviniente@@/g, 'SUB COMISARIA E3');
      

      //Datos de la Comisaria
      str = str.replace(/@@Dependencian_Nombre@@/g, 'SUB COMISARIA E3' );
      str = str.replace(/@@Dependencia_Departamento@@/g, 'RAWSON' );
      str = str.replace(/@@Dependencia_Numero@@/g, '3');
      str = str.replace(/@@Domicilio_Dependencia@@/g,  '20 de Junio y Mistral (Fte. Plaza)' );
            
      //Datos del Damnificado
      str = str.replace(/@@NombreDamnificado@@/g, 'Cristian' );
      str = str.replace(/@@ApellidoDamnificado@@/g, 'Ale');
      str = str.replace(/@@dniDamnificado@@/g, '38507866' );
      str = str.replace(/@@EdadDamnificado@@/g, '21');
      str = str.replace(/@@NacionalidadDamnificado@@/g, 'Argentina' );
      str = str.replace(/@@EstadoCivilDamnificado@@/g, 'Divorciado/a' );
      str = str.replace(/@@DomicilioDamnificado@@/g,'Calle Dr Ortega 648 Este'  );
      str = str.replace(/@@instruccionDamnificado@@/g,'Sabe leer y sabe firmar');
      str = str.replace(/@@TelefonoDamnificado@@/g, '2645963148');
      str = str.replace(/@@ProfesionDamnificado@@/g, 'Tecnico en higiene y seguridad ' );
      str = str.replace(/@@DamnificadosText@@/g, '<b>Ale, Cristian DNI: 38507866 </b> de nacionalidad Argentina, estado civil Divorciado, de 21 años de edad, Sabe leer y sabe firmar, con domicilio en Calle Dr Ortega 648 Este' );
      str = str.replace(/@@DamnificadosText2@@/g, 'Ale, Cristian DNI: 38507866 de nacionalidad Argentina, estado civil Divorciado, de 21 años de edad, Sabe leer y sabe firmar, con domicilio en Calle Dr Ortega 648 Este' )   ;
      str = str.replace(/@@DamnificadosDatosParciales@@/g,'Ale, Cristian DNI: 38507866');;

      //Datos Del Hecho 
      str = str.replace(/@@Lugar_del_Hecho@@/g, 'Av. Paula Albarracín de Sarmiento' );
      str = str.replace(/@@Hora_del_Hecho@@/g, '16:14:00');
      str = str.replace(/@@Fecha_del_Hecho@@/g, '1/1/2022');
      //Datos del/los Acusado/s
      // str = str.replace(/@@Acusados@@/g, (this.data.acusado===undefined || this.data.acusado === null || this.data.acusado === NaN)? 'Persona de Filiacion Desconocida' :this.data.acusado)
      str = str.replace(/@@Acusados@@/g, 'Lucas, Tello DNI: 37807866 de nacionalidad Argentina, estado civil Soltero, de 30 años de edad, Sabe leer y sabe firmar, con domicilio en EE.UU 648 Este' );
      str = str.replace(/@@AcusadosCaratula@@/g, '<b>Lucas, Tello DNI: 37807866 </b> de nacionalidad Argentina, estado civil Soltero, de 30 años de edad, Sabe leer y sabe firmar, con domicilio en EE.UU 648 Este' );
      str = str.replace(/@@AcusadosDatosParciales@@/g, 'Lucas, Tello DNI: 37807866 ' );

      str = str.replace(/@@Apodo@@/g, 'Mandinga');
      
      str = str.replace(/@@ApellidoContraventor@@/g,  'Tello');
      str = str.replace(/@@NombreContraventor@@/g, 'Lucas');


      str = str.replace(/@@DomicilioContraventor@@/g, 'EE.UU 648 Este');

      str = str.replace(/@@ObjetosSustraidos@@/g, 'Celular, Marca Motorola, Modelo g60s, Color Azul');
      
      str = str.replace(/@@CategoríaObjeto@@/g,  'Electronica');
      str = str.replace(/@@SubcategoríaObjeto@@/g,  'Celulares');
      str = str.replace(/@@MarcaObjeto@@/g,  'Panasonic');
      str = str.replace(/@@ModeloObjeto@@/g,  'Generico');
      str = str.replace(/@@TipoObjeto@@/g,  'Tactil');
      str = str.replace(/@@DatosPúblicosObjeto@@/g,  'Esto es un dato público');
      str = str.replace(/@@DatosPrivadosObjeto@@/g,  'Esto es un dato privado');
      str = str.replace(/@@AñoObjeto@@/g,  '2023');
      str = str.replace(/@@NºMotorObjeto@@/g,  'Motor: 45344');
      str = str.replace(/@@NºChasisObjeto@@/g,  'Chaisis: 58399');
      str = str.replace(/@@NºSerieObjeto@@/g,  'Serie objeto: 673209');
      str = str.replace(/@@DominioObjeto@@/g,  'Este es un dominio objeto');
      str = str.replace(/@@NºIMEIObjeto@@/g,  'Nº IMEI: 4376447895');
      str = str.replace(/@@ColorObjeto@@/g,  'Naranja');
    
      return str;
    },
  },

}

</script>
<style>
.titulosDatos{
  color:black;
  font-size: 1em;
}
.contenidoDatos{
  color:cornflowerblue;
  font-size: 1em;
}
.titulos{
  font-size: 1.1em;
  text-decoration: underline;
}

.titulosDatos:hover {
  color: green;
  text-decoration: underline;

}
.disable{
  display:none;
  visibility:hidden 
}
.active{
  display: block;
  visibility:visible 
}
.spinner{
    display: none;
}
.print-page {
  z-index: 999;
  position: absolute;
  display: block;
  top: 0px;
  left: 0px;
  width: calc(100vw );
  height: calc(100vh);
  padding: 10px;
  background-color: #fff;
}
.menuprint {
  padding: 0 20px 10px 0px;
}
.print-page h1 {
  font-size: 14px;
}
.print-page  iframe { 
  height: calc(100vh - 120px);
  width: 100%;
}
</style>
