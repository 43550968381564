import Vue from 'vue'
import axios from 'axios'
var allowCheckLogin = true;
const state = () => ({
  logged: false,
  status: "",
  loginSuccess: false,
  userdata: {},
  uid: null,
  err: null,
  errorLogin: false,
  errorDni: false,
  MailAvailable: true,
  countWrongDni: 0,
  timer: true,
  dependencia: '',
  departamento: '',
  loading: true
})

// actions
const actions = {
  updateloading({ commit }, payload) {
    commit('updateLoading', payload)
  },
  async login({ commit, state, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      // console.log("payload", payload)
      commit('updateTimer', false)
      try {
        let request
        // console.log("login online?", Vue.prototype.$offline.onlineMode)
        if (Vue.prototype.$offline.onlineMode) {
            if(state.MailAvailable){
              request = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/login`, payload, { withCredentials: true })
            } else{
              console.log("login notoken")
              request = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/loginnotoken`, payload, { withCredentials: true })
            }
            if(request?.data?.userData !== undefined) dispatch('saveUserDataInLocalStorage', request.data.userData);

          if(String(request.data).includes("fsockopen(): unable to connect")){
            
            Vue.prototype.$toast.open({ message: "El servicio de mail no esta disponible. Ingrese su DNI", position: 'top-right', type: 'warning', duration: 3000, });
            console.log("mail server not available", request.data)
            commit('setMailAvailable', false);
            resolve()
          }
          if(request.data.status === "Código de verificación requerido"){
            request.data.status = '';
            commit('setLoginSuccess', true);
          }
            
          if(request.data.status === "El dni y\/o la contrase\u00f1a son erroneos"){
            console.log("salio por aca")
            commit('addCountWrongDni');
            commit('setErrorDni', true);
            resolve()
          }
          // request.data.userData.offlineIp = 'https://localhost:3000' //'https://local.electro3.com.ar:3000' 
          if (Vue.prototype.$offline.offlineServer) {


            await Vue.prototype.$offline.setItem('login', {
              update: {
                username: payload.username
              },
              data: {
                ...payload,
              }
            })
            let requestLocal = await axios.post(`${Vue.prototype.$offline.offlineServer}/api/login`, payload, { withCredentials: true })
            request.data.token = requestLocal.data.token
          }
        } else {

          if (Vue.prototype.$offline.offlineServer) {
            request = await axios.post(`${Vue.prototype.$offline.offlineServer}/api/login`, payload, { withCredentials: true })

          } else {
            throw 0;
          }
        }
        if (request.data.status != "ok") {
          if (request.data.attemptsRemaining && state.loginSuccess) {
            request.data.status += ", Intentos restantes: " + (3 - request.data.attemptsRemaining)
          }
          if (request.data.attemptsRemaining && !state.loginSuccess) {
            request.data.status = "Usuario y/o contraseña Incorrecta"
          }
          commit('errorLogin', request.data.status);
          commit('updateTimer', true)
          resolve()
        }
        if (request.data.userCheck) {
          commit('setLoginSuccess', true)
          commit('updateTimer', true)
          commit('setMailAvailable', true);
          resolve()
        }

        if (request.data.tokenCheck || !Vue.prototype.$offline) {
          commit('setLoginSuccess', true)
          if (request.data.token) Vue.prototype.$offline.setActiveJWT(request.data.token);
          let policeStation = {
            "nombre": request.data.userData.dependenciaNombre, //"Sub Comisaria  Médano de Oro - Rawson",
            "telefono": request.data.userData.dependenciaTelefono, //"4215859 - 264-5483183",
            "dependenciaLetra": request.data.userData.dependenciaLetraNombre, // "SUB COMISARIA  MÉDANO DE ORO",
            "interno": "",
            "clavePolicial": request.data.userData.dependenciaClavePolicial, //"",
            "domicilio": request.data.userData.dependenciaDomicilio, //"20 de Junio y Mistral (Fte. Plaza)",
            "id": request.data.userData.dependenciaID, //"25d93dc3-bcb3-4d21-8091-731d1ced261a",
            "header": request.data.userData.dependenciaHeader,
            "departamento": request.data.userData.dependenciaDepartamento
          }
          commit('setDependencia', policeStation.dependenciaLetra)
          commit('setDepartamento', policeStation.departamento)
          request.data.policeStation = policeStation;
          // dispatch('sumario/setDependencia', policeStation.dependenciaLetra);

          commit('setLoginSuccess', false)
          commit('setUserData', request.data.userData)
          commit('updateLogged', true)
          commit('setLoginErr', false)
          commit('errorLogin', false);
          commit('updateTimer', true)
          resolve()
        }
      }
      catch (e) {
        console.log(e);
        commit('setLoginErr', true)
        commit('updateTimer', true)
        commit('errorLogin', true);
        resolve()
      }
    })
  },
  async checkLogin({ commit, state }, payload) {
    if (!allowCheckLogin) return
    allowCheckLogin = false
    setTimeout(()=> {
      allowCheckLogin = true
    }, 5000)
    commit('updateTimer', false)
    let statusServer = 200
    let request
    // console.log("onlineMode", Vue.prototype.$offline.onlineMode)
    try {
      if (Vue.prototype.$offline.onlineMode) {
        request = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/login`, { withCredentials: true })
      } else {
        // console.log("login offlineServer")
        request = await axios.get(`${Vue.prototype.$offline.offlineServer}/api/login`, { withCredentials: true, headers: { Authorization: `Bearer ${Vue.prototype.$offline.activeJWT}` } })
        // await Vue.prototype.$offline.getItem('login', {filter:})
      }
      // request = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/login`, { withCredentials: true })
    }
    catch (e) {
      request = null
      // console.log("LOGIN ONLINE FALLO", e.response.status)
      statusServer = e.response.status
      commit('setLogin', false)
      commit('setLoginErr', true)
      commit('updateTimer', true)
      // commit('errorLogin', true);

      //cancela el loading de la pagina login y iniciar sesión
      commit('updateLoading', false);
      return;
    }

    try {
      // if (!request && !Vue.prototype.$offline.onlineMode) {
      //   request = await axios.get(`${Vue.prototype.$offline.offlineServer}/api/login`, { withCredentials: true, headers: { Authorization: `Bearer ${Vue.prototype.$offline.activeJWT}` } })
      // }
      // console.log("request", request)
      if (!request || request.status != 200) throw 0;
      // request = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/login`, { withCredentials: true })

      if (request.data.userId == 'User Public') { throw { mensaje: "Usuario publico no permitido", nombre: "ExepcionPublicUser" } }


      let policeStation = {
        "nombre": request.data.dependenciaNombre, //"Sub Comisaria  Médano de Oro - Rawson",
        "telefono": request.data.dependenciaTelefono, //"4215859 - 264-5483183",
        "dependenciaLetra": request.data.dependenciaLetraNombre, // "SUB COMISARIA  MÉDANO DE ORO",
        "interno": "",
        "clavePolicial": request.data.dependenciaClavePolicial, //"",
        "domicilio": request.data.dependenciaDomicilio, //"20 de Junio y Mistral (Fte. Plaza)",
        "id": request.data.dependenciaID, //"25d93dc3-bcb3-4d21-8091-731d1ced261a",
        "header": request.data.dependenciaHeader
      }
      commit('setDependencia', policeStation.dependenciaLetra)
      commit('setDepartamento', policeStation.departamento)
      request.data.policeStation = policeStation;
      // dispatch('sumario/setDependencia', policeStation.dependenciaLetra);

      commit('setUserData', request.data)
      commit('setLogin', true)
      commit('setLoginErr', false)
      commit('errorLogin', false);
      commit('updateLogged', true)
      commit('updateTimer', true)
      //cancela el loading de la pagina login y iniciar sesiónn
      commit('updateLoading', false);
      //Se comentaron las siguientes lineas de codigo, por que no dejaban que el user depositoJudicial se logueara
      // if (state.userdata.usuarioNombre == 'DepositoJudicial') {
      //   Vue.prototype.$router.push({ path: `/deposito` })
      // }

    }
    catch (e) {

      Vue.prototype.$toast.open({ message: "Usuario no logueado", position: 'top-right', type: 'error', duration: 3000, });

      commit('setLogin', false)
      commit('setLoginErr', true)
      commit('updateTimer', true)
      // commit('errorLogin', true);

      //cancela el loading de la pagina login y iniciar sesión
      commit('updateLoading', false);

    }
  },
  updateLogged({ commit }, payload) {
    commit('updateLogged', payload)
  },

  async logout({ commit }) {
    commit('updateTimer', true)
    try {
      let request
      if (Vue.prototype.$offline.onlineMode) {
        request = await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/logout`, { withCredentials: true })
      }

      let cleanUserData = {
        id: null
      }
      commit('setUserData', cleanUserData)
      commit('setLogin', false)
      commit('setErrorDni', false)
      commit('setMailAvailable', true)
      
      if (Vue.prototype.$offline.offlineServer){
        request = await axios.get(`${Vue.prototype.$offline.offlineServer}/api/logout`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('activeJWT')}` }
        })
      }
    }
    catch (e) {
      console.error("e", e)

    }
  },
  async saveUserDataInLocalStorage({ commit }, payload) {
    try {
      localStorage.clear();
      let userData = { 
        dependencia: {
          departamento: payload.dependenciaDepartamento,
          dependenciaLetra: payload.dependenciaLetraNombre,
          domicilio: payload.dependenciaDomicilio,
          nombre: payload.dependenciaNombre,
          telefonos: payload.dependenciaTelefono,
        },
        usuario:  {
          nombreUsuario: payload.usuarioNombre,
          tipoUsuario: payload.tipoUsuario,
        } 
      }
      localStorage.setItem('siisOffLineData', JSON.stringify(userData));
      // const siisOffLineData = JSON.parse(localStorage.getItem('siisOffLineData'));
      // console.log(siisOffLineData);
    }
    catch (e) {
      console.error("e", e)
    }
  },
  setLogin({ commit }, status) {
    commit('setLogin', status)
  },
  setLoginErr({ commit }, status) {
    commit('setLogin', status)
  },
  //uncompleted feature
  setUserData({ commit }, payload) {
    commit('setUserData', payload)
  },
  setLogout({ commit }) {
    commit('setLogin', false)
  },
  setDependencia({ commit }, payload) {
    commit('setDependencia', payload)
  },
  setDepartamento({ commit }, payload) {
    commit('setDepartamento', payload)
  },
  clearErrorLogin({ commit }) {
    commit('errorLogin', false);
  },
  clearErrorDni({ commit }) {
    commit('setErrorDni', false);
  },
  toastWrongDni({ commit }) {
    Vue.prototype.$toast.open({ message: "La Validación de Identidad no es correcta. Comuníquese con la Mesa de Ayuda Técnica.", position: 'top-right', type: 'warning', duration: 3000, });
  },
  clearUserState({ commit }) {
    commit('errorLogin', false);
    commit('errorDni', false);
    commit('setUserData', {});
    commit('setLogin', false);
    commit('setLoginErr', false);
    commit('setLoginSuccess', false);
    commit('setMailAvailable', true);
    commit('clearCountWrongDni');
    commit('updateTimer', true);
    commit('updateLogged', false);
    commit('setDependencia', '');
    commit('setDepartamento', '');
  },
}

// mutations
const mutations = {
  setDependencia(state, payload) {
    state.dependencia = payload
  },
  setLoginSuccess(state, payload) {
    state.loginSuccess = payload
  },
  setMailAvailable(state, status) {
    state.MailAvailable = status;
  },
  setDepartamento(state, payload) {
    state.departamento = payload
  },
  setLogin(state, status) {
    state.logged = status
  },
  setLoginErr(state, status) {
    state.err = status
  },
  setUserData(state, payload) {
    //Guardamos en Storage el tipo de usuario, para luego reestringir el acceso a algunas rutas
    localStorage.setItem("tipo usuario", payload.tipoUsuario)
    state.userdata = payload;
    state.uid = payload.userId;
  },
  updateTimer(state, payload) {
    state.timer = payload;
  },
  updateLogged(state, payload) {
    state.logged = payload;
  },
  errorLogin(state, payload) {
    state.errorLogin = payload;
  },
  setErrorDni(state,payload) {
    state.errorDni = payload;
  },
  addCountWrongDni(state) {
    state.countWrongDni++;
  },
  clearCountWrongDni(state) {
    state.countWrongDni = 0;
  },
  updateLoading(state, payload) {
    state.loading = payload;
  },
}

const getters = {
  MailAvailable(state) {
    return state.MailAvailable;
  },
  loginSuccess(state) {
    return state.loginSuccess;
  },
  
  countWrongDni(state) {
    return state.countWrongDni;
  },
  errorLogin(state) {
    return state.errorLogin;
  },
  errorDni(state) {
    return state.errorDni;
  },
  getActuacionesUserByStationId: (state, getters, rootState) => (DependenciaID = null) => {
    //Cuando no se especifica un DependenciaID se retornan todos los oficiales, caso contrario se filtra por el
    if(DependenciaID){
      //Cuando se llama por primera vez a este getters siis.userActuaciones no existe, para que no falle se usa el siguiente if ternario
      return rootState.siis.userList != undefined ? rootState.siis.userList.filter(i => i.DependenciaID == DependenciaID) : ''
    }else{
      return rootState.siis.userList != undefined ? rootState.siis.userList : ''
    }
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}