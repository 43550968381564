
import  DenunciaFormDatosGenerales from '@/pages/sumario/DenunciaFormDatosGenerales'
import  DenunciaForm from "@/pages/sumario/DenunciaForm"
import  MenuPage from "@/pages/sumario/MenuPage"

import  DenunciaFormAcusado from "@/pages/sumario/DenunciaFormAcusado"
import  DenunciaFormObjetos from "@/pages/sumario/DenunciaFormObjetos"
import  DenunciaFormDenunciante from "@/pages/sumario/DenunciaFormDenunciante"
import  DenunciaFormMomento from "@/pages/sumario/DenunciaFormMomento"
import  FormDenunciantes from "@/components/sumario/FormDenunciantes"
import  DenunciaPrint from "@/pages/sumario/DenunciaPrint"

import FormLugarMomento from "@/components/forms/FormLugarMomento";
import FormPersona from "@/components/forms/FormPersona";
import FormObjetos from "@/components/forms/FormAcusado";
import FormAcusado from "@/components/forms/FormAcusado";
import FormIdentikit from "@/components/forms/FormIdentikit";
import FormDatosLegales from "@/components/forms/FormDatosLegales";
import FormPersonalInterviniente from "@/components/forms/FormPersonalInterviniente";
import ContravencionalFormDatos  from '@/pages/sumario/ContravencionalFormDatos'
import NewFormPage  from '@/pages/NewFormPage'
import isRolUserGuard from "@/guards/isUserRolGuard";


import { actuacionesConfig } from './RoutesConfig/actuacionesConfig/actuacionesConfig'


function findPropsForRoute(pathName) {
  const link = actuacionesConfig.find((link) => link.pathName === pathName);
  return link ? link.props : {};
}





export default {
 //actuaciones/''
  component: () => import('@/pages/sumario/LayoutActuaciones'),
  children:[
    {
      path: '',
      name:'actuaciones',
      beforeEnter: isRolUserGuard,
      redirect: "list",
    },
    {
      path: 'list',
      name: 'listadoActuaciones',
      component: () => import('@/pages/sumario/SumarioHomePage'),
    },
    {
      path: 'view/:id',
      name: 'view-id',
      component:DenunciaFormDatosGenerales,

    },
    {
      path: ':mode/:pathName/initial',
      name: 'actuacion-initial',
      component: DenunciaForm,// () => import("@/pages/sumario/DenunciaForm"),
      props: (route) => findPropsForRoute(route.params.pathName)
    },
    {
      path: ':mode/:pathName/final',
      name: 'actuacion-final',
      component: () => import("@/pages/sumario/ContravencionalFormDatos"),
      props: (route) => findPropsForRoute(route.params.pathName)     
    },
    {
      path: ":mode/:pathName/initial/denunciante",
      name: "actuacion-form-denunciante",
      component: DenunciaFormDenunciante ,
      props: (route) => findPropsForRoute(route.params.pathName)
    },
    {
      path: ":mode/:pathName/initial/momento",
      name: "actuacion-form-momento",
      // beforeEnter: isUserRolGuard,
      component: () => import("@/pages/sumario/DenunciaFormMomento"),
      props: (route) => findPropsForRoute(route.params.pathName)
    },
    {
      path: ":mode/:pathName/initial/acusado",
      name: "actuacion-form-acusado",
      // beforeEnter: isUserRolGuard,
      component: () => import("@/pages/sumario/DenunciaFormAcusado"),
      // props: { actuacionType: "sumario-denuncia" , pageTitle: "ACUSADO" },
      props: (route) => findPropsForRoute(route.params.pathName)
    },
    {
      path: ":mode/:pathName/initial/efectos",
      name: "actuacion-form-efectos",
      // beforeEnter: isUserRolGuard,
      component: () => import("@/pages/sumario/DenunciaFormObjetos"),
      props: (route) => findPropsForRoute(route.params.pathName)
    },
    {
      path: ":pathName/menu",
      name: "menu-actuaciones",
      // beforeEnter: isUserRolGuard,
      // component: () => import("@/pages/sumario/MenuPage"),
      component: MenuPage,
      props: (route) => findPropsForRoute(route.params.pathName)
    },
    {
      path: ":mode/:pathName/:documento",
      name: "actuacion-save",
      // beforeEnter: isUserRolGuard,
      component: () => import("@/pages/sumario/DenunciaPrint"),
    },
    {
      path: ":mode/:pathName/:id/addDiligencia",
      name: "actuacion-addDiligencia",
      // beforeEnter: isUserRolGuard,
      component: () => import("@/pages/sumario/DenunciaPrint"),
    },
    {
      path: ":pathName/preview/:documento",
      name: "actuacion-previsualizar",
      // beforeEnter: isUserRolGuard,
      component: () => import("@/pages/sumario/DenunciaPrint"),
    },
    {
      path: "newForm",
      name: "new-form",
      // beforeEnter: isUserRolGuard,
      component: () => import("@/pages/NewFormPage"),
    },
  ]
}
